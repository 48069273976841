import { render, staticRenderFns } from "./Hip.vue?vue&type=template&id=5e67a7e0&scoped=true"
import script from "./Hip.vue?vue&type=script&lang=js"
export * from "./Hip.vue?vue&type=script&lang=js"
import style0 from "./Hip.vue?vue&type=style&index=0&id=5e67a7e0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e67a7e0",
  null
  
)

export default component.exports